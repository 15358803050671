import Vue from 'vue'
import Vuex from 'vuex'
import OauthApi from '@/api/oauth'


Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    publicKey: ''
  },
  mutations: {
    SET_PUBLICEKEY: (state, key) => {
      state.publicKey = key
    }
  },
  actions: {
    setPublicKey({ commit }) {
      return new Promise((resolve, reject) => {
        OauthApi.getPublicKey().then(({ data, code }) => {
          if (code === 200) {
            commit('SET_PUBLICEKEY', data)
            return resolve(data)
          }
          reject()
        }).catch(error => {
          reject(error)
        })
      })
    }
  },
  modules: {
  },
  getters: {
    publicKey: state => state.publicKey

  }
})
