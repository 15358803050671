const messages = {
  'zh-CN': {
    placeholder: ['请输入账号', '请输入密码', '请输入手机号码', '验证码', '重新输入新密码'],
    tips: ['两次密码输入不一致', '手机号不能为空', '请先选择售货机'],
    getCode: '获取验证码',
    confirm: '确定',
    forget: '忘记密码',
    login: '登录',
    logout: '退出登录',
    colon: '：',
    productName: '商品名称',
    productCode: '商品编号',
    news: '货道更换商品有新动态了',
    nonews: '货道更换商品无新动态',
    record: '补货记录单',
    warning: '库存预警',
    return: '退货单',
    trade: '异常领料补录',
    inventory: '盘点单',
    machine: '售货机状态',
    channelLabelCode: '货道号',
    status: ['未完成', '', '已处理'],
    types: ['新上架', '替换', '空置货道', '更换硬件'],
    labelChange: '标签更换',
    cancelStocks: '退库',
    productCapacity: '容量',
    productStockNum: '库存',
    maxPicking: '单天领料最大数',
    minPicking: '单天领料最小数',
    avgPicking: '平均每天领料数',
    nodeName: '工厂名称',
    tradeNumber: '订单号',
    deliveryNumber: '交货单号',
    date: '日期',
    deliveryNum: '出库数',
    replenishNum: '补货数',
    retreatNum: '退货数',
    number: '数量',
    deviceCode: '设备编号',
    inventoryTypes: ['无计划盘点', '特殊盘点', '智能仓盘点'],
    inventoryOrderCode: '盘点单号',
    sapOrderNumber: '盘点凭证',
    tradeNum: '盘点差异',
    deployAddress: '布放地址',
    channelCapacity: '货道容量',
    currentNum: '库存数量'
  },
  en: {
    placeholder: [
      'Please Enter the Account Number',
      'Please Enter the Password',
      'Please Enter the Mobile Phone Number',
      'Verification Code',
      'Re-Enter the New Password'
    ],
    tips: [
      'The Two Passwords You Entered Do Not Match',
      'The Mobile Phone Number Cannot Be Empty',
      'Please Select the Vending Machine First'
    ],
    getCode: 'Get Verification Code',
    confirm: 'OK',
    forget: 'Forget Password',
    login: 'Log In',
    logout: 'Log Out',
    colon: ':',
    productName: 'Product Name',
    productCode: 'Product Code',
    news: 'There Are New Developments of Goods Replaced in The Channel',
    nonews: 'There Are No New Developments of Goods Replaced in The Channel',
    record: 'Replenishment Record Sheet',
    warning: 'Stock Alert',
    return: 'Return Order',
    trade: 'Re-register Abnormal Picks',
    inventory: 'Inventory List',
    machine: 'Vending Machine Status',
    channelLabelCode: 'Channel Code',
    status: ['Unfinished', '', 'Processed'],
    types: ['New Arrival', 'Replace', 'Channel Empty', 'Replace Hardware'],
    labelChange: 'Label Replacement',
    cancelStocks: 'Return',
    productCapacity: 'Capacity',
    productStockNum: 'Stock',
    maxPicking: 'Daily Maximum Picking Quantity',
    minPicking: 'Daily Minimum Picking Quantity',
    avgPicking: 'Average Daily Picking Quantity',
    nodeName: 'Factory Name',
    tradeNumber: 'Order Number',
    deliveryNumber: 'Delivery Order Number',
    date: 'Date',
    deliveryNum: 'Out Quantity',
    replenishNum: 'Replenishment Quantity',
    retreatNum: 'Returned Quantity',
    number: 'Quantity',
    deviceCode: 'Device Code',
    inventoryTypes: [
      'Unplanned Stock-taking',
      'Special Stock-taking',
      'Smart Warehouse Stock-taking'
    ],
    inventoryOrderCode: 'Stock-taking Form No.',
    sapOrderNumber: 'Stock-taking Document',
    tradeNum: 'Inventory Difference',
    deployAddress: 'Placement Address',
    channelCapacity: 'Channel Capacity',
    currentNum: 'Stock Quantity'
  }
}
export default messages