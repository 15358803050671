import dayjs from 'dayjs'

export function formatNumber(number) {
  if (number <= 0) return ''
  else if (number < 100) return number
  else return '99+'
}

export function formatTime(time, reg = 'MM-DD HH:mm') {
  return dayjs(time).format(reg)
}

export function formatType(caveatLevel) {
  if (caveatLevel === 'INVENT_WARN') {
    return 'danger'
  } else if (caveatLevel === 'REPLENISH') {
    return 'warning'
  } else if (caveatLevel === 'DEFAULT') {
    return 'primary'
  }
}