<template>
  <div id="app">
    <!-- <van-dropdown-menu>
      <van-dropdown-item v-model="$i18n.locale" :options="options" />
    </van-dropdown-menu> -->
    <router-view />
  </div>
</template>
<script>
export default {
  data() {
    return {
      options: [
        { value: 'zh-CN', text: '中文' },
        { value: 'en', text: 'English' },
      ],
    }
  }
}
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
