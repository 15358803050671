import axios from './lib/axios'
const prefix = '/katrade'

export default {
  // 领料趋势
  listPickingTrend(params = {}) {
    return axios.get(`${prefix}/listPickingTrend`, { params })
  },
  // 查询交易记录补录机器
  listVmChannelTradeAdditionalLogVm(params = {}) {
    const { companyId, empId } = JSON.parse(localStorage.getItem('userInfo')) || {}
    return axios.post(`${prefix}/listVmChannelTradeAdditionalLogVm`, { companyId, empId, ...params })
  },
  // 查询机器下的所有待审核交易记录补录
  listTradeAdditionalApplet(params = {}) {
    const { companyId } = JSON.parse(localStorage.getItem('userInfo')) || {}
    return axios.post(`${prefix}/listTradeAdditionalApplet`, { companyId, ...params })
  },
  // 补货日志列表
  listZkhReplenishLog(params = {}) {
    const { companyId, empId } = JSON.parse(localStorage.getItem('userInfo')) || {}
    return axios.post(`${prefix}/listZkhReplenishLog`, { companyId, empId, ...params })
  },
  // 补货计划下单个补货记录详情列表
  listReplenishLogAll(params = {}) {
    const { companyId } = JSON.parse(localStorage.getItem('userInfo')) || {}
    return axios.post(`${prefix}/listReplenishLogAll`, { companyId, ...params })
  },

  

}