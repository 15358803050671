import axios from './lib/axios'
import util from '@/utils/util'
const prefix = '/vm/applet'

export default {
  // 机器信息详情
  findVmBasicInfo(params) {
    return axios.get(`${prefix}/findVmBasicInfo`, { params })
  },
  // 机器货道商品库存
  listVmChannelStockPage(params = {}) {
    return axios.post(`${prefix}/listVmChannelStockPage`, params)
  },
  // 工厂补货需求详情列表
  vmReplenishDemand(params = {}) {
    return axios.post(`${prefix}/vmReplenishDemand`, { demandOrigin: 1, ...params })
  },
  // 小程序预警
  listCaveatDemandVm(params = {}) {
    const { companyId, empId } = JSON.parse(localStorage.getItem('userInfo')) || {}
    return axios.post(`${prefix}/listCaveatDemandVm`, { companyId, empId, ...params })
  },
  // 用户所在不同点位机器列表
  listEmpNodeVm(params = {}) {
    const { empId } = JSON.parse(localStorage.getItem('userInfo')) || {}
    return axios.get(`${prefix}/listEmpNodeVm`, { params: { empId, ...params } })
  },
  // 查询是否存在机器货道变更商品
  isVmChannelChangeProduct(params = {}) {
    const { empId } = JSON.parse(localStorage.getItem('userInfo')) || {}
    return axios.get(`${prefix}/isVmChannelChangeProduct`, { params: { startDate: util.getDateTime(), empId, ...params } })
  },
  // 更新货道变更日志已读未读状态
  updateChannelChangeReadStatus(params = {}) {
    const { empId } = JSON.parse(localStorage.getItem('userInfo')) || {}
    return axios.get(`${prefix}/updateChannelChangeReadStatus`, { params: { startDate: util.getDateTime(), empId, ...params } })
  },
  // 货道变更任务详情列表
  findVmChannelChangeProductTaskDetail(params = {}) {
    const { empId } = JSON.parse(localStorage.getItem('userInfo')) || {}
    return axios.get(`${prefix}/findVmChannelChangeProductTaskDetail`, { params: { empId, ...params } })
  }
}