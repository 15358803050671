import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import api from './api'
import * as filter from './filter'
import Vant from 'vant'
import 'vant/lib/index.css'
import VeLine from 'v-charts/lib/line.common'
import VueI18n from 'vue-i18n'
import messages from './lang'

import '@/style/main.css'

Vue.prototype.$api = api

Object.keys(filter).forEach(key => {
    Vue.filter(key, filter[key])
})

Vue.use(Vant)
Vue.use(VueI18n)

Vue.component(VeLine.name, VeLine)

Vue.config.productionTip = false

const i18n = new VueI18n({ locale: 'en', messages })

new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
}).$mount('#app')