// 工具方法类

// 格式化数字
const formatNumber = n => {
  n = n.toString()
  return n[1] ? n : '0' + n
}

/**
 * object 转 query string
 * @param obj 需要转化的对象
 */
const queryStr = (obj = {}) => {
  return Object.keys(obj).map(v => `${encodeURIComponent(v)}=${encodeURIComponent(obj[v])}`).join('&')
}

// 防抖函数
const debounce = (fn, delay = 500) => {
  let timer = null
  return function () {
    clearTimeout(timer)
    timer = setTimeout(() => {
      fn.apply(this, arguments)
    }, delay)
  }
}

// 节流函数
const throttle = (fn, delay = 5000) => {
  let preTime = +new Date()
  return function () {
    const nowTime = +new Date()
    if (nowTime - preTime >= delay) {
      fn.apply(this, arguments)
      preTime = nowTime
    }
  }
}

// 返回警戒值，按高->低排列
const getWarnStat = (caveatLevel) => {
  if (caveatLevel == 'INVENT_WARN') {
    return 2
  } else if (caveatLevel == 'REPLENISH') {
    return 1
  } else {
    return 0
  }
}

// 获取5天前日期
const getDateTime = () => {
  const startDate = new Date((new Date).getTime() - 5 * 24 * 60 * 60 * 1000)
  return [startDate.getFullYear(), startDate.getMonth() + 1, startDate.getDate()].map(formatNumber).join('-')
}

// 模糊搜索
const filter = (target, ...arg) => {
  const reg = new RegExp(target, 'i')
  return arg.map(v => reg.test(v)).some(v => v)
}

module.exports = {
  formatNumber,
  queryStr,
  debounce,
  throttle,
  getWarnStat, // 获取警告值
  getDateTime,
  filter
}