import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/forget',
    name: 'Forget',
    component: () => import('../views/Forget.vue')
  },
  {
    path: '/channel',
    name: 'Channel',
    component: () => import('../views/Channel.vue')
  },
  {
    path: '/record',
    name: 'Record',
    component: () => import('../views/Record.vue')
  },
  {
    path: '/recordDetail',
    name: 'RecordDetail',
    component: () => import('../views/RecordDetail.vue')
  },
  {
    path: '/warning',
    name: 'Warning',
    component: () => import('../views/Warning.vue')
  },
  {
    path: '/warningDetail',
    name: 'WarningDetail',
    component: () => import('../views/WarningDetail.vue')
  },
  {
    path: '/warningChart',
    name: 'WarningChart',
    component: () => import('../views/WarningChart.vue')
  },
  {
    path: '/return',
    name: 'Return',
    component: () => import('../views/Return.vue')
  },
  {
    path: '/returnDetail',
    name: 'ReturnDetail',
    component: () => import('../views/ReturnDetail.vue')
  },
  {
    path: '/trade',
    name: 'Trade',
    component: () => import('../views/Trade.vue')
  },
  {
    path: '/tradeDetail',
    name: 'TradeDetail',
    component: () => import('../views/TradeDetail.vue')
  },
  {
    path: '/inventory',
    name: 'Inventory',
    component: () => import('../views/Inventory.vue')
  },
  {
    path: '/inventoryDetail',
    name: 'InventoryDetail',
    component: () => import('../views/InventoryDetail.vue')
  },
  {
    path: '/machine',
    name: 'Machine',
    component: () => import('../views/Machine.vue')
  },
  {
    path: '/machineDetail',
    name: 'MachineDetail',
    component: () => import('../views/MachineDetail.vue')
  },
]

const router = new VueRouter({
  routes
})

export default router
