import basic from './basic'
import oauth from './oauth'
import katrade from './katrade'
import vm from './vm'

export default {
  ...basic, // 基础数据服务
  ...oauth, // 登录授权服务
  ...katrade, // 交易日志服务
  ...vm, // 机器服务
}