import axios from 'axios'
import router from '@/router'
import { Toast } from 'vant';

const service = axios.create({
  baseURL: '/m',
  timeout: 300000
})

// 请求拦截器
service.interceptors.request.use(request => {
  request.headers['Accept-Language'] = 'en-US'
  const authToken = localStorage.getItem('token')
  if (authToken) {
    request.headers['auth_token'] = authToken
  }
  return request
})

// 响应拦截器
service.interceptors.response.use(response => {
  if (response.data.code === 200) {
    return response.data
  } else if (response.data.code === 401) { // token过期
    router.replace('/login')
    return Promise.reject(response.data)
  } else { // 其他错误
    return Promise.reject(response.data)
  }
}, (error) => {
  console.log('error===>', error)
  if(error.message && error.message === 'Network Error') {
    console.log('Network')
    Toast('Network Error')
  }
  if (error.response.status === 401) { // token过期
    router.replace('/login')
  }
  return Promise.reject(error.response.data)
})

export default service